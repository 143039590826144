.btn-cta {
  background: #e9531d;
  border-radius: 10px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 00;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: auto;
}

.flexSwitchCheckDefault {
  font-size: 20px;
  font-weight: 400;
  color: #4f4f4f;
}

/* Custom Switch Color */
.form-check-input:checked {
  background-color: #e9531d !important;
  border-color: #e9531d !important;
}

/* For Bootstrap 5, targeting the switch's thumb when checked */
.form-check-input:checked + .form-check-label::before {
  background-color: #fff !important;
}

/* Additional styling to handle focus shadow to match the color */
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(233, 83, 29, 0.25) !important;
}

#vidModal {
  .close span {
    padding: 0 10px 0 0;
  }
}
.aspect-ratio-16-9 {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

#book-now {
  overflow: hidden;
}

.slider-boxes img {
  height: auto;
}

#pills-home {
  img {
    height: auto;
  }
}

input.postcode {
  text-transform: uppercase;

  &::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }
  &::placeholder {
    /* Recent browsers */
    text-transform: none;
  }
}

.vid-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #e9531d;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9531d;
  border-radius: 10px;
  width: 300px;
  margin: 0 auto;
  font-family: "Lato", sans-serif;
  span {
    margin: 0 10px 0 0;
  }
}

.top-section {
  background: linear-gradient(to bottom right, #fffbf3 0%, #edfaff 50%, #ffffff 100%);

  // background: url(../images/banner01.webp) no-repeat;

  background-size: 100%;
  // padding: 2rem 0 4rem;
  // @include max(992px) {
  //    padding: 2rem 0 2rem;
  // }
  // @include max(575px) {
  //     padding: 2rem 5px 2rem;
  //  }

  .bo-btn {
    background: #e9531d;
    border-radius: 10px;
    padding: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    width: 100%;
    text-decoration: none;
  }

  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #333;
    span {
      font-weight: 900;
      color: #e9531d;
      img {
      }
    }
    @include max(1200px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  h1 {
    text-align: center;
    span {
      color: #e9531d;
    }
  }

  .pricing-box {
    background-color: #fffbfa !important;
    box-shadow: 0px 2px 23px rgba(62, 73, 84, 0.04) !important;
  }

  .box {
    height: 100%;
    padding: 30px 40px;
    background: #ffffff;
    border: 1px solid #e9531d;
    border-radius: 12px;

    .treatment-price {
      font-size: 42px;
      font-weight: 600;
    }

    .klarna-text {
      color: #4f4f4f;
      font-size: 20px;
      font-weight: medium;
    }

    .klarna-img {
      height: 23px;
      background-color: #ffb3c7;
      border-radius: 7px;
      width: 62px;
      padding: 5px;
      margin-bottom: 5px;
    }

    .cp-img {
      height: 25px;
      background-color: #b2fce4;
      border-radius: 7px;
      width: 80px;
      padding: 5px;
      margin-bottom: 5px;
    }

    .consultation-title {
      font-size: 28px;
      text-transform: unset;
      margin-right: 7px;
      font-weight: 600;
    }

    .consultation-subtitle {
      font-size: 16px;
      margin-top: 10px;
    }

    .consultation-img {
      margin-right: 10px;
    }

    .consultation-text {
      font-size: 20px;
      font-weight: medium;
    }

    .terms-text {
      font-size: 15px;
      color: 999999;
    }

    @include max(1200px) {
      padding: 30px 25px;
      .klarna-text {
        font-size: 18px;
      }

      .treatment-price {
        font-size: 32px;
        font-weight: 600;
      }

      .terms-text {
        font-size: 12.5px;
      }

      .consultation-img {
        max-height: 48px;
        max-width: 48px;
      }
      .consultation-text {
        font-size: 18px;
      }
      .consultation-title {
        font-size: 29px;
      }

      .consultation-subtitle {
        font-size: 13px;
      }
    }

    @include max(575px) {
      .klarna-img {
        height: 18px;
        width: 40px;
        margin-bottom: 2px;
        border-radius: 5px;
      }

      .cp-img {
        height: 18px;
        width: 52px;
        padding: 4px;
        margin-bottom: 2px;
        border-radius: 5px;
      }

      .consultation-title {
        font-size: 26px;
      }
      .treatment-price {
        font-size: 26px;
      }
      .consultation-subtitle {
        font-size: 13px;
        margin-top: 12px;
        margin-left: 2px;
      }
      .consultation-text {
        font-size: 12px;
      }
      .klarna-text {
        font-size: 13px;
      }
      .terms-text {
        font-size: 11px;
      }
      .consultation-img {
        max-height: 36px;
        max-width: 36px;
        margin-right: 3px;

        @include max(392px) {
          max-height: 27px;
          max-width: 27px;
          margin-right: 1px;
        }
      }
    }
    h2,
    h3 {
      font-weight: 900;
      font-size: 32px;
      line-height: 34px;
      color: #25242b;
      text-align: left;
      @include max(1400px) {
        font-size: 25px;
        line-height: 30px;
      }
      @include max(1200px) {
        font-size: 22px;
        line-height: 16px;
      }
      @include max(575px) {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .search {
      border: 1px solid #e9531d;
      border-radius: 10px;
      padding: 0 0 0 60px;

      width: 100%;
      font-weight: 500;
      font-size: 24px !important;
      line-height: 50px !important;
      color: #e9531d;
      background: url(/assets/images/svg-icons/search-orange.svg) center left 15px no-repeat;
      &::placeholder {
        color: #e9531d;
      }
      @include max(575px) {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .small {
      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #4f4f4f;
        margin: 0;
        @include max(1367px) {
          font-size: 16px;
          line-height: 18px;
        }
        @include max(992px) {
          font-size: 18px;
          line-height: 20px;
        }
        @include max(575px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    .switch {
      width: 50px;
    }

    @include min(992px) {
      .switch {
        width: 40px;
      }
    }

    @include min(1367px) {
      .switch {
        width: 70px;
      }
    }

    a {
      padding: 0px 15px;
      text-align: center;
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #4f4f4f;
      @include max(575px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #4f4f4f;
      margin: 0 0 30px;
      @include max(575px) {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .img-text {
      display: flex;
      align-items: center;
      margin: 0 0 30px;
      @include max(1280px) {
        margin: 0 0 25px;
      }
      @include max(767px) {
        flex-direction: column;
        text-align: center;
        margin: 0 0 25px;
      }
      h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #404048;
        margin: 0;
        padding: 0 0 0 1.5rem;
        @include max(767px) {
          padding: 0;
          font-size: 15px;
          line-height: 17px;
        }
      }
    }
    .b-text {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      h4 {
        font-weight: 900;
        font-size: 52px;
        line-height: 62px;
        text-align: right;
        color: #e9531d;
        @include max(575px) {
          font-size: 42px;
          line-height: 44px;
        }
      }
    }
  }
  .bg {
    position: relative;
    z-index: 1;

    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      top: 5px;
      right: 0px;
      width: 136px;
      height: 170px;
      background-image: url("/assets/images/icon13.png"); /* Logo image */
      background-size: cover;
      background-position: center;
      z-index: -1;

      @include max(1200px) {
        width: 128px;
        height: 165px;
      }

      @include max(767px) {
        width: 108px;
        height: 135px;
      }
    }
  }
}
.help-area {
  h2 {
    font-weight: 800;
    font-size: 42px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #323232;
    margin: 0 0 50px;
    position: relative;
    &::before {
      position: absolute;
      top: auto;
      right: 0;
      left: 0;
      bottom: -30px;
      width: 200px;
      height: 5px;
      background: #e9531d;
      content: "";
      margin: 0 auto;
      @include max(992px) {
        width: 150px;
      }
    }
    @include max(1200px) {
      font-size: 38px;
      line-height: 40px;
    }
    @include max(992px) {
      font-size: 30px;
      line-height: 32px;
    }
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #404048;
  }
  .box {
    padding: 40px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    height: 250px;
    @include max(1200px) {
      padding: 20px 10px;
      height: 200px;
    }
    @include max(992px) {
      margin: 0 0 1.5rem;
    }
    @include max(767px) {
      padding: 20px;
      flex-direction: inherit;
      height: auto;
    }
    @include max(767px) {
      padding: 10px;
      height: 75px;
    }
    img {
      height: auto;
      margin: 0 0 30px;
      @include max(1200px) {
        margin: 0 0 15px;
      }
      @include max(767px) {
        margin: 0 10px 0 0;
        width: 25%;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      text-align: center;
      color: #000000;
      margin: 0;
      @include max(1367px) {
        font-size: 20px;
        line-height: 22px;
      }
      @include max(992px) {
        font-size: 16px;
        line-height: 18px;
      }
      @include max(767px) {
        text-align: left;
      }
    }
  }
}

.foll-area-wrapper {
  // background: url(../images/banner02.webp) no-repeat;
  // background-size: auto;
  // background-size: cover;
  position: relative;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.foll-area {
  padding: 4rem 0 6rem;
  height: 70vh;
  position: relative;

  &.active {
    height: unset;

    img {
      transform: rotate(180deg);
    }
  }

  .container {
    overflow: hidden;
    height: 100%;
    -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
  }

  .btn-show {
    position: absolute;
    bottom: 0;
  }

  @include max(1367px) {
    padding: 4rem 0 4rem;
  }
  @include max(992px) {
    background-size: cover;
    padding: 2rem 0;
  }
  @include max(575px) {
    padding: 2rem 5px;
  }
  h2 {
    font-weight: 300;
    font-size: 42px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #e9531d;
    margin: 0 0 35px;
    position: relative;
    @include max(992px) {
      font-size: 30px;
      line-height: 32px;
    }
    &:before {
      position: absolute;
      top: auto;
      right: 0;
      left: 0;
      bottom: -30px;
      width: 200px;
      height: 5px;
      background: #fff;
      content: "";
      margin: 0 auto;
    }
  }
  .link-box.active {
    height: auto;
  }

  .locations-list {
    ul {
      padding: 0;
      margin: 0;
      li {
        margin: 10px 0;
        display: inline-block;
        text-align: center;
        width: 50%;

        @include min(992px) {
          width: 25%;
        }
      }
    }
    a {
      color: #fff;
      display: block;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 26px;
      padding: 1rem 0;
      text-transform: capitalize;

      &:hover {
        color: darken(white, 20%);
      }
    }
  }

  .link-box {
    // display: flex;
    padding: 2.5rem 0 0;
    @include max(1367px) {
      padding: 2.5rem 0 0;
    }
    @include max(992px) {
      padding: 1.5rem 0 0;
    }
    @include max(575px) {
      flex-wrap: wrap;
      padding: 1.5rem 2.5rem 0;
      height: 300px;
      overflow: hidden;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        margin: 10px 0;

        a {
          display: block;
          padding: 1rem 0;
          font-weight: 600;
          font-size: 24px;
          line-height: 26px;
          letter-spacing: -0.5px;
          text-transform: capitalize;
          color: #ffffff;
          @include max(1367px) {
            font-size: 20px;
            line-height: 22px;
          }
          @include max(1200px) {
            font-size: 16px;
            line-height: 18px;
          }
          @include max(992px) {
            font-size: 13px;
            line-height: 15px;
          }
          @include max(767px) {
            font-size: 11px;
            line-height: 13px;
          }
          @include max(575px) {
            font-size: 15px;
            line-height: 19px;
          }
        }
      }
    }
  }
  .btn-show {
    left: 50%;
    right: 50%;
    width: 130px;
    margin-left: -65px;
    text-align: center;

    button {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 17px;
      letter-spacing: -0.5px;
      text-transform: capitalize;
      color: #ffffff;
      opacity: 0.75;
      display: flex;
      align-items: center;
      img {
        margin: -3px 5px 0 0;
      }
      &:focus {
        color: #ffffff;
        opacity: 0.75;
      }
    }
  }
}
.work-detail {
  @include max(992px) {
    padding: 0;
  }
  @include max(575px) {
    padding: 0 5px;
  }
  h2 {
    font-weight: 800;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #323232;
    position: relative;
    margin: 0 0 60px;
    @include max(992px) {
      font-size: 30px;
      line-height: 32px;
      margin: 0 0 50px;
      text-align: center;
    }
    &:before {
      position: absolute;
      top: auto;
      right: 0;
      left: 0;
      bottom: -25px;
      width: 100px;
      height: 4px;
      background: #e9531d;
      border-radius: 2px;
      content: "";
      @include max(992px) {
        margin: 0 auto;
      }
    }
  }
  ul {
    padding: 0 15px 0 0;
    position: relative;
    z-index: 1;
    @include max(575px) {
      padding: 0;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 20px;
      right: auto;
      content: "";
      width: 1px;
      height: 90%;
      background: #e9531d;
      z-index: -1;
      @include max(767px) {
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    li {
      display: flex;
      align-items: flex-start;
      margin: 0 0 40px;
      @include max(767px) {
        margin: 0 0 20px;
      }
      .img-hold {
        width: 10%;
      }
      .text-hold {
        padding: 0;
        width: 90%;
        @include max(1200px) {
          padding: 0 0 0 10px;
        }
        @include max(992px) {
          padding: 0;
        }
        @include max(767px) {
          padding: 15px;
          background: #ffffff;
          border: 1px solid #e9531d;
          border-radius: 8px;
          width: 100%;
        }
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 22px;
          color: #e9531d;
          margin: 0;
          @include max(1200px) {
            margin: 0 0 7px;
            font-size: 18px;
            line-height: 20px;
          }
          @include max(992px) {
            display: flex;
            align-items: center;
            img {
              margin: 0 7px 0 0;
            }
          }
        }
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
          color: #404048;
          margin: 0;
          @include max(1200px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.three-block h2,
h2.underline {
  font-weight: 800;
  font-size: 42px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #323232;
  margin: 0 0 60px;
  position: relative;
  @include max(992px) {
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 40px;
  }
  &:before {
    position: absolute;
    top: auto;
    right: 0;
    left: 0;
    bottom: -30px;
    width: 150px;
    height: 4px;
    background: #e9531d;
    border-radius: 2px;
    content: "";
    margin: 0 auto;
  }
}

.three-block {
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    content: "";
    bottom: auto;
    background: url(../images/icon13.webp) no-repeat;
    width: 160px;
    height: 200px;
    background-size: 100%;
    @include max(1200px) {
      width: 120px;
    }
    @include max(575px) {
      display: none;
    }
  }

  .boxs {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    text-align: center;
    padding: 1rem;
    height: 260px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    display: flex;

    @include max(992px) {
      width: 60%;
      margin: 0 auto 20px;
    }
    @include max(992px) {
      width: 80%;
      margin: 0 auto 20px;
    }
    @include max(992px) {
      width: 100%;
    }
    img {
      margin: 0 0 20px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #404048;
      @include max(1367px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
.video-block {
  padding: 0;
  @include max(992px) {
    padding: 0 0 2rem;
  }
  @include max(575px) {
    padding: 0 5px 2rem;
  }
  h2 {
    font-weight: 800;
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #323232;
    text-align: left;
    margin: 0 0 35px;
    position: relative;
    text-align: center;
    span {
      color: #e9531d;
    }
    &:before {
      position: absolute;
      top: auto;
      right: 0;
      left: 0;
      bottom: -20px;
      width: 100px;
      height: 4px;
      background: #e9531d;
      border-radius: 2px;
      content: "";
      margin: 0 auto;
    }
  }
  .vid-block {
    position: relative;
    img {
      height: auto;
    }
    .desk {
      @include max(992px) {
        margin: 0;
      }
    }
    @include max(992px) {
      text-align: center;
    }
    .capation {
      position: absolute;
      top: -15%;
      left: -6.5rem;
      right: 0;
      bottom: 0;
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      @include max(1367px) {
        left: -4.5rem;
      }
      @include max(992px) {
        top: 0;
        left: 0;
      }
    }
  }
  .vid-text {
    padding: 2.5rem 3rem 0 0rem;
    @include max(1200px) {
      padding: 0;
    }
    h2 {
      font-weight: 800;
      font-size: 38px;
      line-height: 50px;
      letter-spacing: -0.5px;
      text-transform: capitalize;
      color: #323232;
      text-align: left;
      margin: 0 0 35px;
      position: relative;
      @include max(1200px) {
        br {
          display: none;
        }
      }
      span {
        color: #e9531d;
      }
      &:before {
        position: absolute;
        top: auto;
        right: auto;
        left: 0;
        bottom: -20px;
        width: 100px;
        height: 4px;
        background: #e9531d;
        border-radius: 2px;
        content: "";
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #404048;
      margin: 0 0 20px;
      @include max(1200px) {
        margin: 0 0 10px;
        line-height: 22px;
      }
    }
  }
}
.mo-block {
  padding: 15rem 0 15rem;
  background: url(../images/banner03.webp) no-repeat center top;
  background-size: 100%;
  position: relative;
  z-index: 99;
  @include max(1800px) {
    padding: 13rem 0 13rem;
  }
  @include max(1700px) {
    padding: 12rem 0 12rem;
  }
  @include max(1600px) {
    padding: 11rem 0 11rem;
  }
  @include max(1500px) {
    padding: 10rem 0 10rem;
  }
  @include max(1400px) {
    padding: 9rem 0 9rem;
  }
  @include max(1367px) {
    padding: 7rem 0 7rem;
  }
  @include max(1200px) {
    padding: 6rem 0 6rem;
  }
  @include max(992px) {
    padding: 2rem 0;
    background: #fdeee8;
  }
  @include max(575px) {
    padding: 2rem 5px;
  }
  h2 {
    font-weight: 800;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #000000;
    margin: 0 0 30px;
    text-align: center;
    @include max(992px) {
      font-size: 30px;
      line-height: 32px;
      margin: 0 0 20px;
    }
  }
  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #404048;
    margin: 0 0 60px;
    text-align: center;
    @include max(1150px) {
      margin: 0 0 20px;
    }
    @include max(992px) {
      text-align: left;
      font-size: 20px;
      line-height: 22px;
    }
    @include max(992px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  .mo-box {
    background: #ffffff;
    border-radius: 20px;
    height: 160px;
    @include min(992px) {
      height: 200px;
      margin: 0 0 20px;
    }
    @include min(1200px) {
      height: 180px;
      padding: 15px;
    }
    &:hover {
      background: #e9531d;
      p {
        color: #fff;
      }
      h3 {
        color: #fff;
      }
    }
    h3 {
      font-weight: 800;
      font-size: 24px;
      line-height: 26px;
      color: #000000;
      margin: 0 0 20px;
      @include max(1200px) {
        margin: 0 0 15px;
      }
      @include max(992px) {
        margin: 0 0 10px;
      }
      @include max(575px) {
        font-size: 16px;
        line-height: 18px;
      }
      @include max(370px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #404048;
      margin: 0;
      text-align: left;
      @include max(992px) {
        line-height: 28px;
      }
      @include max(767px) {
        line-height: 22px;
      }
      @include max(575px) {
        line-height: 18px;
        font-size: 14px;
      }
      @include max(370px) {
        line-height: 16px;
        font-size: 13px;
      }
    }
  }
}
.blog-area {
  background: #fff;
  position: relative;
  z-index: 1;
  // &::before {
  //     position: absolute;
  //     top: -10%;
  //     left: 0;
  //     right: 0;
  //     width: 100%;
  //     height: 10%;
  //     background: #fff;
  //     z-index: -1;
  //     content: "";
  //     @include max(1400px) {
  //        top: -14%;
  //        height: 14%;
  //     }
  // }
  h2 {
    font-weight: 800;
    font-size: 42px;
    line-height: 44px;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    color: #323232;
    margin: 0 0 40px;
    position: relative;

    @include max(992px) {
      font-size: 30px;
      line-height: 32px;
      text-align: center;
    }

    span {
      color: #e9531d;
    }

    &:after {
      position: absolute;
      bottom: -25px;
      left: 50%;
      width: 140px;
      height: 4px;
      background: #e9531d;
      border-radius: 2px;
      content: "";
      transform: translateX(-50%); // This ensures the line is centered

      @include max(992px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #404048;
    margin: 0 0 40px;
  }
  .blog-block {
    @include max(992px) {
      display: flex;
      max-width: 100%;
      overflow: auto;
    }
    .block {
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      height: 100%;
      @include max(1200px) {
        margin: 0 0 10px;
      }
      @include max(992px) {
        margin: 0 10px 0;
        width: 50%;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
      }
      @include max(767px) {
        width: 70%;
      }
      @include max(575px) {
        width: 85%;
      }
      img {
        width: 100%;
      }
    }
    .text {
      padding: 15px 15px;
      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #e9531d;
        margin: 0 0 10px;
        @include max(1367px) {
          font-size: 18px;
          line-height: 20px;
          margin: 0 0 7px;
        }
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #404048;
        margin: 0;
        @include max(1367px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
.dp-area {
  padding: 0 0 3rem;
  @include max(992px) {
    padding: 0 0 3rem;
  }
  .box {
    background: #ffffff;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
      0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01),
      0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185),
      0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
    border-radius: 20px;
    padding: 3rem 0;
    text-align: center;
    position: relative;
    z-index: 1;
    @include max(1200px) {
      padding: 2rem 0;
    }
    @include max(992px) {
      padding: 1.5rem 0;
    }
    @include max(767px) {
      padding: 0.7rem 0;
    }
    @include max(575px) {
      padding: 40px 15px;
      border-radius: 10px;
    }
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      content: "";
      background: url(../images/banner04.webp) no-repeat;
      width: 100%;
      height: 100%;
      background-size: 100%;
      z-index: -1;
      @include max(575px) {
        background: url(../images/banner05.webp) no-repeat;
        background-size: cover;
      }
    }
    h3,
    h2 {
      font-weight: 700;
      font-size: 52px;
      line-height: 54px;
      text-align: center;
      color: #1d1d1d;
      margin: 0 0 30px;
      span {
        color: #e9531d;
      }
      @include max(1200px) {
        font-size: 40px;
        line-height: 44px;
        margin: 0 0 20px;
      }
      @include max(992px) {
        font-size: 30px;
        line-height: 32px;
        margin: 0 0 10px;
      }
      @include max(767px) {
        font-size: 20px;
        line-height: 22px;
        margin: 0 0 10px;
      }
      @include max(575px) {
        font-size: 32px;
        line-height: 46px;
        margin: 0 0 20px;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #484848;
      margin: 0 0 40px;
      @include max(1200px) {
        margin: 0 0 20px;
      }
      @include max(992px) {
        margin: 0 0 15px;
        font-size: 12px;
        line-height: 14px;
      }
      @include max(575px) {
        margin: 0 0 20px;
      }
    }

    .bok-btn {
      background: #e9531d;
      border-radius: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      padding: 15px 15px;
      border: none;
      width: 150px;
      @include max(767px) {
        font-size: 14px;
        line-height: 16px;
      }
      @include max(575px) {
        font-size: 18px;
        line-height: 25px;
        width: 100%;
      }
    }
  }
}
code {
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #f5f5f5;
  color: #e9531d !important;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: monospace;
  white-space: nowrap;
}

.copy-btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  margin-left: 6px;
  opacity: 0.6;
  transition: opacity 0.2s, transform 0.2s;
}

.copy-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

.tooltip .tooltip-inner {
  text-align: left !important;
  white-space: normal !important;
  max-width: 200px;
}

.appointment-landing-card {
  background-color: white !important;
  border: 1px solid #f8f9fa !important;
  border-radius: 10px !important;
  padding: 50px !important;
  cursor: pointer !important;

  @include max(575px) {
    padding: 25px !important;
  }
}
